<template>
  <v-container>
    <v-data-table
      :loading="loading_data_table"
      :headers="headers"
      :items="desserts"
      sort-by="corporate_name"
      class="elevation-1"
      :pageCount="numberOfPagesPaginate"
      :page="currentPagePaginate"
      :server-items-length="totalStagesPaginate"
      :options.sync="options"
      :footer-props="{
        itemsPerPageText: 'Itens por pagina',
        itemsPerPageOptions: [5, 10, 15],
        showFirstLastPage: true,
      }"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Empreiteiros</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>

          <!-- <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="mr-2" color="primary" fab small dark v-bind="attrs" v-on="on">
                <v-icon>mdi-tools</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="showConfigIntegration()">
                <v-icon class="mr-2"> mdi-cog</v-icon>
                Integração

              </v-list-item>

            </v-list>
          </v-menu> -->

          <!-- <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="loadingRefresh" :loading="loadingRefresh" @click="updateContractor()" fab small
                color="primary" v-bind="attrs" v-on="on">
                <v-icon>mdi-refresh </v-icon>
              </v-btn>
            </template>
            <span>Atualizar Empreiteiros</span>
          </v-tooltip> -->

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Localizar"
            @click:append="searchItems()"
            @change="searchItems()"
            single-line
            hide-details
            class="mr-8"
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <!-- <v-icon class="mr-2" title="Composições" @click="getCompositions(item)"> mdi-format-list-group</v-icon> -->
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" icon class="mr-2" @click="showHistoric(item)">
              <v-icon> mdi-list-box </v-icon>
            </v-btn>
          </template>
          <span>Histórico</span>
        </v-tooltip>

        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              class="mr-2"
              color="primary"
              @click="dialogView(item)"
            >
              <v-icon> mdi-eye </v-icon>
            </v-btn>
          </template>
          <span>Visualizar</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data> Nenhum registro encontrado </template>
    </v-data-table>

    <!-- dialog visualizar -->
    <v-dialog v-model="dialog" max-width="900px">
      <v-card>
        <div class="title_body">
          <span class="text_title text-h6">EMPREITEIRO</span>
          <span class="btn_close">
            <v-btn icon dark @click="dialog = false">
              <v-icon dark> mdi-close </v-icon>
            </v-btn>
          </span>
        </div>

        <v-card-text>
          <v-row no-gutters>
            <v-col cols="2">
              <v-text-field
                disabled
                class="mr-2"
                outlined
                v-model="editedItem.id"
                label="Codigo"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field
                disabled
                class="mr-2"
                outlined
                v-model="editedItem.cpf"
                label="CPF"
              ></v-text-field>
            </v-col>
            <v-col cols="">
              <v-text-field
                disabled
                outlined
                v-model="editedItem.cnpj"
                label="CNPJ"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col>
              <v-text-field
                disabled
                class="mr-2"
                outlined
                v-model="editedItem.fantasy_name"
                label="Nome"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                disabled
                outlined
                v-model="editedItem.company_name"
                label="Razão Social"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-text-field
                disabled
                class="mr-2"
                outlined
                v-model="editedItem.state_registration"
                label="Inscrição Estadual"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                disabled
                outlined
                v-model="editedItem.municipal_registration"
                label="Inscrição Municipal"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-text-field
                disabled
                class="mr-2"
                outlined
                v-model="editedItem.city"
                label="Cidade"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                class="mr-2"
                disabled
                outlined
                v-model="editedItem.state"
                label="Estado"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                disabled
                outlined
                v-model="editedItem.cep"
                label="CEP"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row no-gutters>
            <v-col>
              <v-text-field
                disabled
                outlined
                v-model="editedItem.email"
                label="Email"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider class="mb-4"></v-divider>
        <v-card-text>
          <v-row no-gutters v-for="(contact, index) of editedItem.contact" :key="index">
            <v-col>
              <v-text-field
                dense
                class="mr-2"
                disabled
                outlined
                v-model="contact.name"
                label="Nome"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                dense
                class="mr-2"
                disabled
                outlined
                v-model="contact.occupation"
                label="Função"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                dense
                class="mr-2"
                disabled
                outlined
                v-model="contact.telephone"
                label="Telefone"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                dense
                disabled
                outlined
                v-model="contact.email"
                label="Email"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="mx-0 mt-3 mr-1" text @click="dialog = false">Fechar</v-btn>
          <!-- <v-btn class="primary mx-0 mt-3" :loading="btnLoadingSave" :disabled="btnLoadingSave" @click="save">Salvar</v-btn>  -->
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfigIntegration" width="800">
      <v-card :disabled="loadingConfigIntegration">
        <BarTitle
          title="Configuração de Integração"
          @close="dialogConfigIntegration = false"
        ></BarTitle>
        <v-card-text>
          <v-text-field
            v-model="configIntegration.url"
            outlined
            label="Dominio de integração"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-0 mt-3 mr-1"
            x-large
            text
            @click="dialogConfigIntegration = false"
            >Fechar</v-btn
          >
          <v-btn
            class="primary mx-0 mt-3"
            x-large
            :loading="btnLoadingConfigSave"
            :disabled="btnLoadingConfigSave"
            @click="saveConfigIntegration()"
            >Salvar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapActions } from "vuex/dist/vuex.common.js";
import BarTitle from "@/pages/Components/BarTitle.vue";
export default {
  components: {
    BarTitle,
  },
  data: () => ({
    dialogConfigIntegration: false,
    configIntegration: {},
    btnLoadingConfigSave: false,
    loadingConfigIntegration: false,

    loadingRefresh: false,
    totalStagesPaginate: 0,
    numberOfPagesPaginate: 0,
    currentPagePaginate: 0,
    options: {},
    search: "",

    btnLoadingSave: false,
    loading_data_table: false,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "NOME FANTASIA",
        align: "start",
        sortable: false,
        value: "fantasy_name",
      },
      {
        text: "RAZÃO SOCIAL",
        value: "company_name",
      },
      {
        text: "CNPJ",
        value: "cnpj",
      },
      {
        text: "CPF",
        value: "cpf",
      },
      {
        text: "",
        value: "actions",
        align: "end",
        sortable: false,
      },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      id: "",
      code: "",
      name: "",
    },
    defaultItem: {
      id: "",
      code: "",
      name: "",
    },
  }),

  computed: {},

  watch: {
    dialog(val) {
      val || this.close();
    },
    // dialogDelete(val) {
    //   val || this.closeDelete();
    // },
    options: {
      handler() {
        this.initialize();
      },
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    ...mapActions("Contractor", [
      "index",
      "getUpdateContractor",
      "storeConfigIntegration",
      "loadConfigIntegration",
    ]),
    initialize() {
      //carrega a loista dos empreiteiros
      this.loading_data_table = true;
      const { page, itemsPerPage } = this.options;
      this.index(itemsPerPage || 10, page, this.search).then((response) => {
        this.desserts = response.data.data;
        this.totalStagesPaginate = response.data.total;
        this.numberOfPagesPaginate = response.data.last_page;
        this.currentPagePaginate = response.data.current_page;
        this.loading_data_table = false;
      });
    },
    showConfigIntegration() {
      // carrega as configuracoes de integracao de empreiteiro
      this.loadingConfigIntegration = true;
      this.loadConfigIntegration()
        .then((response) => {
          this.configIntegration.url = response.data.url;
        })
        .finally(() => {
          this.loadingConfigIntegration = false;
        });
      this.dialogConfigIntegration = true;
    },
    saveConfigIntegration() {
      this.btnLoadingConfigSave = true;
      this.storeConfigIntegration(this.configIntegration)
        .then(() => {
          this.dialogConfigIntegration = false;
          this.$toast.success("Salvo com sucesso");
        })
        .finally(() => {
          this.btnLoadingConfigSave = false;
        });
    },
    searchItems() {
      this.options = {};
      this.initialize();
    },
    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    updateContractor() {
      this.loadingRefresh = true;
      this.getUpdateContractor()
        .then(() => {
          this.$toast.success("Atualizado com sucesso.");
          this.initialize();
        })
        .finally(() => {
          this.loadingRefresh = false;
        });
    },
    showHistoric(item) {
      this.$router.push({
        name: "contractor_historic",
        params: { contractor_id: item.id },
      });
    },
    dialogView(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },


    // deleteItem(item) {
    //   this.editedIndex = this.desserts.indexOf(item);
    //   this.editedItem = Object.assign({}, item);
    //   this.dialogDelete = true;
    // },

    // deleteItemConfirm() {
    //   let editedIndex = this.editedIndex;
    //   service.destroy(this.editedItem).then(() => {
    //     this.desserts.splice(editedIndex, 1);
    //     this.$toast.success("Deletado com sucesso.");
    //   });

    //   this.closeDelete();
    // },

    // close() {
    //   this.dialog = false;
    //   this.$nextTick(() => {
    //     this.editedItem = Object.assign({}, this.defaultItem);
    //     this.editedIndex = -1;
    //   });
    // },
    // closeDelete() {
    //   this.dialogDelete = false;
    //   this.$nextTick(() => {
    //     this.editedItem = Object.assign({}, this.defaultItem);
    //     this.editedIndex = -1;
    //   });
    // },
    // save() {
    //   this.btnLoadingSave= true
    //   if (this.editedIndex > -1) {
    //     //update
    //     let indice = this.editedIndex;
    //     service.update(this.editedItem).then((response) => {
    //       this.btnLoadingSave= false
    //       Object.assign(this.desserts[indice], response.data);
    //        this.close();
    //        this.$toast.success("Salvo com sucesso.");

    //     }).catch(()=>{
    //       this.btnLoadingSave= false
    //     })

    // } else {
    //     //store
    //     service.store(this.editedItem).then((response) => {
    //       this.btnLoadingSave= false
    //       this.desserts.push(response.data);
    //       this.close();
    //       this.$toast.success("Salvo com sucesso.");
    //       if (this.desserts.length == 1){ this.initialize()}
    //     }).catch(()=>{
    //       this.btnLoadingSave= false
    //     })
    //   }

    // },
    // getCompositions(item){
    //   this.setCompositionGroupId(item.id)
    //   this.$router.push("/system/register/composition")
    // }
  },
};
</script>
